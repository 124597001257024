import React from "react";
import { Modal, Button } from "react-bootstrap";
import OrderForm from "./ModalForms/OrderForm";
import FilterForm from "./ModalForms/FilterForm";

export default function ModalComponent(props) {
  const modalDataAction = () => {
    switch (props.modalDataAction.action) {
      case "order":
        return <OrderForm onHide={props.onHide} />;
      case "filter":
        return <FilterForm onHide={props.onHide} />;
      default:
        return null;
    }
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.modalDataAction.headingTitle}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>{modalDataAction()}</Modal.Body>
      {props.showFooter && (
        <Modal.Footer>
          <Button onClick={props.onHide}>Cerrar</Button>
        </Modal.Footer>
      )}
    </Modal>
  );
}
