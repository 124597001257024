import requests, { parseObjToQuery } from "../utils/requests";
import { getLocalStorage } from "../utils/localStorage";

export const vehicleCustomDataPost = async ({ idMarca, model, version }) => {
  const url = `${process.env.REACT_APP_API_URL}/api/vehicleCustomData`;
  return await requests(url, "POST", { idMarca, model, version });
};

export const postPublication = async (data) => {
  const url = `${
    process.env.REACT_APP_API_URL
  }/api/publications?access_token=${getLocalStorage("token")}`;
  return await requests(url, "POST", data);
};

export const putPublication = async (idPublication, data) => {
  const url = `${process.env.REACT_APP_API_URL}/api/publications/${idPublication}`;
  return await requests(url, "PUT", data);
};

export const putPublications = async (data) => {
  const url = `${process.env.REACT_APP_API_URL}/api/publications/`;
  return await requests(url, "PUT", data);
};

export const patchPublication = async (idPublication, data) => {
  const url = `${process.env.REACT_APP_API_URL}/api/publications/${idPublication}`;
  return await requests(url, "PATCH", data);
};

export const getPublication = async () => {
  const url = `${process.env.REACT_APP_API_URL}/api/publications`;
  return await requests(url, "GET");
};

export const deletePublication = async (id) => {
  const url = `${process.env.REACT_APP_API_URL}/api/publications/${id}?`;
  return await requests(url, "DELETE");
};

export const getPublicationHome = async ({
  userPublication = "",
  allStatusPublications = "",
  allStatus = false,
  orderBy = null,
  filterBy = null,
  skip = 0,
  title = "",
  category = "",
  dolarValue = "",
}) => {
  let query = {};

  if (filterBy) {
    query = { ...filterBy };
  }
   
  if (orderBy) {
    query.order = orderBy;
  }
  
  if (title) query.title = title;

  if (category) query.category = category;

  if (userPublication) query.userPublication = userPublication;

  if (allStatus) query.allStatus = allStatus;

  if (allStatusPublications)
    query.allStatusPublications = allStatusPublications;

  if (dolarValue) query.dolarValue = dolarValue;
  query.skip = skip;
  query.allStatus = allStatus;
  const url = `${
    process.env.REACT_APP_API_URL
  }/api/publications/list${parseObjToQuery(query)}&access_token=${
    getLocalStorage("token") ?? null
  }`;
  return await requests(url, "GET");
};

export const getAllUserPublications = async () => {
  const url = `${
    process.env.REACT_APP_API_URL
  }/api/publications/getAllUserPublications?access_token=${
    getLocalStorage("token") ?? null
  }`;
  return await requests(url, "GET");
};

export const getPublicationCountUser = async () => {
  const url = `${
    process.env.REACT_APP_API_URL
  }/api/people/allowed-to-create-new-publication?access_token=${
    getLocalStorage("token") ?? null
  }`;
  return await requests(url, "GET");
};

export const getPublicationsByCategory = async (
  vehicleCategoryId,
  personId
) => {
  const filter = `filter[where][personId]=${personId}&filter[where][vehicleCategoryId]=${vehicleCategoryId}`;
  const url = `${process.env.REACT_APP_API_URL}/api/publications?${filter}`;
  return await requests(url, "GET");
};

export const getDetailSlug = async (slug) => {
  const url = `${
    process.env.REACT_APP_API_URL
  }/api/publications/details/${slug}?access_token=${
    getLocalStorage("token") ?? null
  }`;
  return await requests(url, "GET");
};

export const getPublicationId = async (id) => {
  const url = `${process.env.REACT_APP_API_URL}/api/publications/edit/${id}?`;
  return await requests(url, "GET");
};

export const postPublicationMultimedia = async (publicationId, data) => {
  const url = `${
    process.env.REACT_APP_API_URL
  }/api/publicationMediaResources/file-upload?publicationId=${publicationId}&access_token=${getLocalStorage(
    "token"
  )}`;
  return await requests(url, "POST", data);
};

export const saveFavorite = async (publicationId) => {
  const personId = getLocalStorage("userId");
  if (!personId) return;
  const favorite = await getFavorite(publicationId, personId);
  if (favorite.data.length) return;
  const data = {
    publicationId,
    personId,
  };
  const url = `${
    process.env.REACT_APP_API_URL
  }/api/favorites?&access_token=${getLocalStorage("token")}`;
  return await requests(url, "POST", data);
};

export const deleteFavorite = async (publicationId) => {
  const personId = getLocalStorage("userId");
  if (!personId) return;
  let favorite = await getFavorite(publicationId, personId);
  favorite = favorite.data[0];
  if (!favorite) return;
  const url = `${process.env.REACT_APP_API_URL}/api/favorites/${
    favorite.id
  }?&access_token=${getLocalStorage("token")}`;
  return await requests(url, "DELETE");
};

export const getFavorites = async () => {
  const personId = getLocalStorage("userId");
  const filter = {
    include: {
      publication: [
        "publicationMediaResources",
        "vehicleCustomData",
        { vehiculoVersion: { vehicleModel: "vehicleMake" } },
      ],
    },
    where: {
      personId: personId,
    },
  };

  const url = `${
    process.env.REACT_APP_API_URL
  }/api/favorites?filter=${JSON.stringify(filter)}`;
  return await requests(url, "GET");
};

export const getFavorite = async (publicationId, personId) => {
  const filter = `filter[where][personId]=${personId}&filter[where][publicationId]=${publicationId}`;
  const url = `${process.env.REACT_APP_API_URL}/api/favorites?${filter}`;
  return await requests(url, "GET");
};

export const getRelationsCars = async (category, make, id) => {
  const url = `${process.env.REACT_APP_API_URL}/api/publications/related?categoryId=${category}&makeId=${make}&id=${id}`;
  return await requests(url, "GET");
};

export const getRelationsPrice = async (
  priceVehicle,
  category,
  publicationId
) => {
  const url = `${process.env.REACT_APP_API_URL}/api/publications/relatedPrice?price=${priceVehicle}&categoryId=${category}&id=${publicationId}`;
  return await requests(url, "GET");
};

export const getRelationsYear = async (
  yearVehicle,
  category,
  publicationId
) => {
  const url = `${process.env.REACT_APP_API_URL}/api/publications/relatedYearCategory?year=${yearVehicle}&category=${category}&id=${publicationId}`;
  return await requests(url, "GET");
};

export const deletePublicationMedia = async (publicationId, filesToDelete) => {
  const url = `${
    process.env.REACT_APP_API_URL
  }/api/publicationMediaResources/file-delete?publicationId=${publicationId}&access_token=${getLocalStorage(
    "token"
  )}`;
  return await requests(url, "PUT", filesToDelete);
};

export const postEmail = async (publicationUrl, formData) => {
  const { name, emailFrom, emailTo, phone, message, publicationImg } = formData;
  const url = `${process.env.REACT_APP_API_URL}/api/publications/sendEmail`;
  return await requests(url, "POST", {
    name,
    emailFrom,
    emailTo,
    phone,
    message,
    publicationUrl,
    publicationImg,
  });
};

export const getCountPublicationsByUser = async (personId) => {
  const url = `${process.env.REACT_APP_API_URL}/api/publications/idCount/${personId}`;
  return await requests(url, "GET");
};

export const postPublicationBulkEdit = async (
  publicationIds,
  percentageValue,
  roundResult
) => {
  const url = `${process.env.REACT_APP_API_URL}/api/publications/bulk-edit`;
  return await requests(url, "POST", {
    publicationIds,
    amount: percentageValue,
    roundResult,
  });
};
