import { Route } from "wouter";

import HomePage from "./pages/HomePage";
import SignInPage from "./pages/SignInPage";
import SignUpPage from "./pages/SignUpPage";
import ForgetPasswordPage from "./pages/ForgetPasswordPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import ConfirmEmailPage from "./pages/ConfirmEmailPage";
import DashboardPage from "./pages/Dashboard/DashboardPage";
import FavoritesPage from "./pages/Dashboard/FavoritesPage";
import MyPublicationsPage from "./pages/Dashboard/MyPublicationsPage";
import AllPublicationsPage from "./pages/Dashboard/AllPublicationsPage";
import PublicationsBulkUpdate from "pages/Dashboard/PublicationsBulkUpdate";
import UsersPage from "./pages/Dashboard/UsersPage";
import PublicationDetailPage from "./pages/PublicationDetailPage";
import PublicationPage from "./pages/PublicationPage";
import ConcessionairePage from "./pages/ConcessionairePage";
import { UserContextProvider } from "./context/UserContext";
import { ReloadContextProvider } from "./context/ReloadContext";

function App() {
  return (
    <UserContextProvider>
      <div className="App">
        <section className="App-content">
          <Route path="/signin" component={SignInPage} />
          <Route path="/signup" component={SignUpPage} />
          <Route path="/forget-password" component={ForgetPasswordPage} />
          <Route path="/reset-password" component={ResetPasswordPage} />
          <Route path="/confirm-email" component={ConfirmEmailPage} />

          <ReloadContextProvider>
            <Route path="/" component={HomePage} />
            <Route path="/favorites" component={FavoritesPage} />
            <Route
              path="/vehicle/detail/:slug"
              component={PublicationDetailPage}
            />
            <Route path="/concessionaire/:id" component={ConcessionairePage} />
          </ReloadContextProvider>

          <Route
            path="/dashboard/my-publications"
            component={MyPublicationsPage}
          />
          <Route
            path="/dashboard/allPublicationsPage"
            component={AllPublicationsPage}
          />

          <Route
            path="/dashboard/publications-bulk-update"
            component={PublicationsBulkUpdate}
          />

          <Route path="/dashboard" component={DashboardPage} />

          <Route path="/dashboard/users-page" component={UsersPage} />

          <Route path="/publication" component={PublicationPage} />
          <Route path="/publication/:id" component={PublicationPage} />
        </section>
      </div>
    </UserContextProvider>
  );
}

export default App;
