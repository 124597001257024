import React from "react";
import { OrderByContextProvider } from "../../context/OrderByContext";
import { FilterByContextProvider } from "../../context/FilterByContext";
import NavBar from "../../layouts/NavBar";
import MyPublicationComponent from "components/Publication/MyPublicationComponent";
import FooterPage from "components/Footer/FooterPage";
import { Nav } from "react-bootstrap";
import { PrevIcon } from "utils/styled-components/icons";
import { TEXTMARKET } from "components/Publication/styles";
import GoBackComponent from "components/GoBackComponent";

export default function MyPublicationsPage() {
  
  return ( 
    <>
    <NavBar></NavBar>
    <GoBackComponent/>
    <div className="publicationshome-wrapper px-3 mb-2 mt-4">
    <div className="publicationshome-wrapper__left">

      <OrderByContextProvider>
        <FilterByContextProvider>
          <MyPublicationComponent/>
        </FilterByContextProvider>
      </OrderByContextProvider>
      
    </div>
    </div>

    <FooterPage />
    </>
  );
}
