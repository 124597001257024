import React, { useEffect, useState, useContext } from "react";
import Skeleton from "react-loading-skeleton";
import { Container, Row, Col } from "react-bootstrap";
import swal from "sweetalert";
import Context from "../../context/OrderByContext";
import FilterByContext from "../../context/FilterByContext";
import { getPublicationHome, patchPublication, deletePublication } from "../../services/publication";
import PublicationCard from "../../components/Publication/PublicationCard";
import { getLocalStorage } from "../../utils/localStorage";
import Pagination from "../../components/Publication/Publications/Pagination";
import InputSearch from "components/InputSearch";
import { TEXTALERT } from "components/Publication/styles";
import PublicationsFilter from "../../components/Publication/Publications/filter"


export default function MyPublicationComponent() {
    const [publications, setPublications] = useState([]);
    const [reload, setReload] = useState(false);
    const [loading, setLoading] = useState(false);
    const { orderBy } = useContext(Context);
    const { filterBy } = useContext(FilterByContext);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPublication, setTotalPublication] = useState(0);
    const [isTyping, setIsTyping] = useState(false);
    const [time, setTime] = useState(null);
    const [fromSearch, setFs] = useState("");
    let userPublication = "";
    const publicationPerPage = 20;
    let category = "";
  
    let controlCategory = false;
    let personId = getLocalStorage("userId");
  
    const [updateData] = useState({
      personId: personId,
      vehicleCategoryId: "",
      priceUpdate: "",
    });
  
    useEffect(() => {
      if (updateData.vehicleCategoryId === "") {
        getPublications(controlCategory);
      } else {
        controlCategory = true;
        getPublications(controlCategory);
      }     
    }, [filterBy, orderBy, reload]);
  
    const getPublications = async (page, title = null, controlCategory) => {

      setLoading((prev) => true);
      const skip = ((!page ? currentPage : page) - 1) * publicationPerPage;
  
      let queries = { orderBy, filterBy, skip };

      userPublication = personId;
      if (userPublication) queries.userPublication = userPublication;
  
      if (title) queries.title = title;
  
      queries.allStatus = true;
  
      if (controlCategory && userPublication) {
        category = updateData.vehicleCategoryId;
        queries.category = category;
      }
  
      const response = await getPublicationHome(queries); 
      if (response?.status === 200) {
        const objectCars = Object.values(response.data.response).map(
          (relation, index) => {
            return {
              id: relation.id,
              personId: relation.personid,
              currencyType: relation.currencytype,
              price: relation.price,
              km: relation.km,
              year: relation.year,
              vehicleCategoryId: relation.vehiclecategoryid,
              versionId: relation.versionid,
              vehicleVersion: relation.versionname,
              modelId: relation.modelid,
              vehicleModel: relation.modelname,
              makeId: relation.makeid,
              vehicleMake: relation.makename,
              customId: relation.customid,
              customMake: relation.custommake,
              customModel: relation.custommodel,
              customVersion: relation.customversion,
              imageUrl: relation.url,
              statusId: relation.statusid,
              slugUrl: relation.slugurl,
              isFavorite: !!relation.favorite,
              full_count: relation.full_count,
              createdAt: relation.createdat,
              modifiedAt: relation.modifiedat,
              index,
            };
          }
        );
        try {
          setTotalPublication(objectCars[0]?.full_count || 0);
          setPublications(objectCars);
          setCurrentPage(!page ? 1 : page ?? currentPage);
        } catch (err) {
          console.log(err);
        }
        setLoading((prev) => false);       
      }
    };
    const handlePagination = async (page) => {
      if (page === currentPage) return;
      if (fromSearch) {
        alert(page);
        set(fromSearch, page);
      } else {
        await getPublications(page);
      }     
    };
    const set = async (val, page) => {
      if (val === "") {
        setLoading(true);
        setFs(false);
        await setCurrentPage((prev) => 1);
        await getPublications(1);
        setLoading(false);
        setIsTyping(false);
        return;
      } else {
        setFs((prev) => val);
        await setCurrentPage((prev) => page ?? 1);
        await getPublications(page, val);
        setIsTyping((prev) => false);
      }
    };
    const handleSearch = (val) => {
      setIsTyping((prev) => true);
      clearTimeout(time);
      setTime(
        setTimeout(() => {
          set(val, null);
        }, 500)
      );
    };
  
    const handleStatus = async (publicationId, status, event) => {
      event.stopPropagation();
      event.preventDefault();
      setLoading(true);
      await patchPublication(publicationId, { statusId: status });
      setReload(!reload);
      setLoading(false);
    };
  
    const displayDeleteSwall = async (id, event) => {
      event.stopPropagation();
      event.preventDefault();
  
      swal("ATENCION! se eliminara definitivamente ¿Esta seguro?", {
        icon: "warning",
        buttons: {
          catch: {
            text: "Cancelar",
            value: "catch",
          },
  
          Eliminar: true,
        },
      }).then((value) => {
        switch (value) {
          case "Eliminar":
            handleDelete(id, event);
            break;
  
          case "catch":
            swal("Acción cancelada!");
            break;
  
          default:
            console.log("Nada");
            break;
        }
      });
    };
  
    const handleDelete = async (id, event) => {
      event.stopPropagation();
      event.preventDefault();
      setLoading(true);
      await deletePublication(id);
  
      swal("Tu archivo ha sido eliminado!", {
        icon: "success",
      });
  
      setReload(!reload);
      setLoading(false);
    };
  
    return (
      <>
        
        <Container>
          <h5>Mis publicaciones</h5>
          <div className="filter-search-order mt-5">
            <PublicationsFilter />
            <InputSearch
              name="seachPublications"
              handleSearch={(e) => handleSearch(e.target.value)}
              placeholder="Buscar publicaciones"
              isTyping={isTyping}
              classes="shadow-sm"
            />
          </div>
          {loading ? (
            <Skeleton
              containerClassName="skeleton-home mb-3"
              height={250}
              width={"100%"}
              count={12}
            />
          ) : publications.length <= 0 ? (
            <TEXTALERT>No hay publicaciones</TEXTALERT>
          ) : (
            <Col md="12" className="p-0">
              <Row className="justify-content-md-center">
                {publications?.map((publication) => (
                  <Col md="4" key={publication.id}>
                    <PublicationCard
                      userPublication={personId}
                      data={publication}
                      showFavorite={false}
                      handleStatus={handleStatus}
                      handleDelete={handleDelete}
                      displayDeleteSwall={displayDeleteSwall}
                      carousel={false}
                      disabledStatusButton={true}
                      editBtn={true}
                    />
                  </Col>
                ))}
              </Row>
            </Col>
          )}
          <Pagination
            publicationPerPage={publicationPerPage}
            totalPublication={totalPublication}
            paginate={handlePagination}
            currentPage={currentPage}
          />
        </Container>
      </>
    );
  }
